import { graphql } from 'gatsby';
import {arrayOf, shape, string} from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';

import { Content } from '../../common/content';
import './calltoaction.scss';
import {Button} from "../../common/button";
import {getLinkProps} from "../teaser/utils";
import {useTranslation} from "react-i18next";

const bem = new BEMHelper({
  name: 'content-calltoaction',
});

export const CallToAction = ({
  id,
  headline,
  reference
}) => {

  const { t } = useTranslation();

  return(
    <Content id={id} {...bem()}>
      <h2 {...bem('headline')}>
        {parse(headline)}
      </h2>
      <div {...bem('details')}>
        <Button
          {...bem('button')}
          title={reference.title ? reference.title : t('Read more about "{{ topic }}"', { topic: headline })}
          {...getLinkProps(reference)}
        >
          {reference.text}
        </Button>
      </div>
    </Content>
  )};

CallToAction.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  reference: shape({
    url: string,
    text: string,
    target: string,
    title: string,
    element: shape({
      slug: string.isRequired,
      departments: arrayOf(shape({
        slug: string.isRequired,
        color: string.isRequired,
      })),
    }),
  }).isRequired,
};

export const fragment = graphql`
  fragment CallToActionContentFragment on CraftGQL_pageContents_callToAction_BlockType {
    id
    headline
    reference {
      url
      text
      target
      title
      element {
        ...on CraftGQL_pages_pages_Entry {
          __typename
          slug: pageSlug
          departments: pageDepartments {
            slug
            ...on CraftGQL_departments_department_Entry {
              color: departmentColor
            }
          }
        }

        ...on CraftGQL_departments_department_Entry {
          __typename
          slug,
          color: departmentColor
        }

        ...on CraftGQL_products_product_Entry {
          __typename
          slug
          departments: productDepartment {
            ...on CraftGQL_departments_department_Entry {
              slug
              color: departmentColor
            }
          }

          variant: productVariants(limit: 1) {
            ...on CraftGQL_variants_variants_Entry {
              slug
            }
          }
        }
      }
    }
  }
`;
